import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Label } from "semantic-ui-react";
import { userContext } from "../App";
import {
	token as tokenAtom,
	author as authorAtom,
	federated as federatedAtom,
	idToken as idTokenAtom,
} from "../components/Atoms";
import { SubmitContext } from "./Workspace";
import { useMutation, queryCache } from "react-query";
import { useRecoilState } from "recoil";
import calculateAspectRatio from "calculate-aspect-ratio";
import "./Dropzone.css";

const Dropzone = ({
	id,
	handleClose,
	make,
	setMake,
	files,
	setCaptured,
	setFiles,
	setSubmitted,
	setSubmittedUrl,
	style,
	setLoading,
	styleData,
	setStylesFunc,
	getNotFoundPic,
	setUploading,
	prodDescription,
	openModal,
	gallery,
}) => {
	/**************************************************************************************/
	//Cloudfront Invalidation
	const [federated, setFederated] = useRecoilState(federatedAtom);
	const [token, setToken] = useRecoilState(tokenAtom);
	const [federatedAuthor, setFederatedAuthor] = useRecoilState(authorAtom);
	const [idToken, setIdToken] = useRecoilState(idTokenAtom);

	const user = useContext(userContext);

	const setSubmitting = useContext(SubmitContext);
	let auth, author;
	if (federated === "yes") {
		auth = token;
		author = federatedAuthor;
	} else {
		if (user?.signInUserSession.idToken.jwtToken) {
			auth = idToken;
			author = user.signInUserSession.idToken.payload.email;
		}
	}

	const picAngle = styleData.filter((i) => {
		return i.picture_angle === make;
	});

	let revision;
	if (styleData.length === 0) {
		revision = 1;
	} else if (styleData[0].revision === null) {
		revision = 1;
	} else if (picAngle.length === 0) {
		revision = 1;
	} else {
		revision = Number(picAngle[0].revision) + 1;
	}

	const handleChange = (e) => {
		const filesArray = e.target.files;
		setFiles(filesArray);
		let img = new Image();
		img.src = window.URL.createObjectURL(e.target.files[0]);
		// img.onload = () => {
		// 	const aspectRatio = calculateAspectRatio(img.width, img.height);
		// 	alert(
		// 		`Resolution: ${img.width}x${img.height}\nAspect Ratio: ${aspectRatio}`
		// 	);
		// };
	};
	let description;

	var date = new Date();
	var dd = String(date.getDate()).padStart(2, "0");
	var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
	var yyyy = date.getFullYear();

	date =
		mm +
		"/" +
		dd +
		"/" +
		yyyy +
		" - " +
		date.getHours() +
		":" +
		date.getMinutes() +
		":" +
		date.getSeconds();
	async function update() {
		const res = await axios.get(`${process.env.REACT_APP_ENDPOINT}captured`, {
			headers: {
				Authorization: auth,
			},
		});
		let capturedArray = [];
		res.data.forEach((item) => {
			capturedArray.push(item.style_id);
		});
		setCaptured(capturedArray);
		const stylesUpdate = await axios.get(
			`${process.env.REACT_APP_ENDPOINT}info/${id}`,
			{
				headers: {
					Authorization: auth,
				},
			}
		);
		setStylesFunc(stylesUpdate.data);
		console.log(stylesUpdate.data);
		alert("Picture uploaded!");
		setLoading(false);
	}

	useEffect(() => {
		if (styleData.length === 0) {
		} else {
			description = styleData[0].style_web_description;
		}
	}, [styleData]);

	const prodID = id + "-" + make + `_${revision}`;

	if (make === "") {
		setMake("01");
	}

	const handleSubmit = async () => {
		setLoading(true);
		const body = {
			lcid: null,
			style_id: id,
			hero_ind: "yes",
			picture_angle: make,
			revision: revision,
			style_web_description: prodDescription,
			revision_date: date,
			author: author,
			url:
				process.env.REACT_APP_CLOUDFRONT +
				"/original/" +
				style +
				"-" +
				make +
				`_${revision}` +
				".jpg",
		};
		const url = await axios.get(
			`${process.env.REACT_APP_ENDPOINT}update/${prodID}`,
			{
				headers: {
					Authorization: auth,
				},
			}
		);
		try {
			const response = await axios.put(url.data.uploadURL, files[0], {
				headers: {
					"Content-Type": "image/jpg",
					"Access-Control-Allow-Origin": "*",
					crossorigin: "anonymous",
				},
			});
			if (response.status === 200) {
				setSubmittedUrl(
					`${process.env.CLOUDFRONT}` + id + "-" + make + ".jpg"
				);
				setSubmitted(true);
			const dbReponse = await axios.post(`${process.env.REACT_APP_ENDPOINT}image`, body, {
				headers: {
					Authorization: auth,
				},
			});
			if (dbReponse.status !== 200) {
				setLoading(false);
				alert("Picture could not be uploaded.");
			}
			if (dbReponse.status === 200) {
				setUploading(true);
				update();
				openModal();
				setUploading(false);
			}
			
			} else {
				console.log("failed");
			};
		}

		catch(e) {
			console.log(e);
		}


	};

	const [mutate] = useMutation(handleSubmit, {
		onSuccess: () => {
			queryCache.invalidateQueries("pics");
		},
	});

	return (
		<>
			{" "}
			<div
				className="dropzone-container"
				style={{
					displa: "flex",
					flexDirection: "column",
					alignItems: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
						marginTop: "20px",
					}}
				>
					<Label pointing="below">Upload Picture</Label>
					<input
						onChange={handleChange}
						type="file"
						id="file-input"
						name="file-input"
						accept="image/png, image/jpeg"
					></input>
				</div>

				<div className="buttons">
					<button className="button1" onClick={handleClose}>
						Close
					</button>
					<button className="button1" onClick={mutate}>
						Save
					</button>
				</div>
			</div>
		</>
	);
};

export default Dropzone;
