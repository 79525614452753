import React, { useState, useContext, useEffect, useRef } from "react";
import MaterialTable, { MTableGroupbar } from "material-table";
import { useQuery } from "react-query";
import { uploadModal as uploadModalAtom } from "./Atoms";
import { useRecoilState } from "recoil";
import { itemCount as itemCountAtom } from "./Atoms";
import { ReactComponent as Icon } from "../images/Spinner.svg";
import { Auth } from "aws-amplify";

import axios from "axios";

const Items = ({
	items,
	handleOpen,
	setSelected,
}) => {
	const [itemCount, setItemCount] = useRecoilState(itemCountAtom);

	const tableRef = React.useRef();

	const [uploadModal, setUploadModal] = useRecoilState(uploadModalAtom);
	const style = {
		textAlign: "left",
	};

	// useEffect(() => {
	// 	async function getHistory() {
	// 		const user = await Auth.currentAuthenticatedUser();
	// 		const idToken = user.signInUserSession.idToken.jwtToken;
	// 		const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}history`, {
	// 			headers: {
	// 				Authorization: idToken,
	// 			},
	// 		});

	// 		console.log(response.data);
	// 		if (response?.data.length > 0) {

	// 			setItems(response.data);
	// 			setItemCount(response.data.length);
	// 		}
	// 	}
	// 	getHistory();
	// }, []);

	async function getHistory() {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}history`, {
			headers: {
				Authorization: idToken,
			},
		});

		console.log(response.data);
		if (response?.data.length > 0) {
			setItemCount(response.data.length);
		}
		return response.data;
	}

	const {data, isLoading} = useQuery("items", getHistory);


	const [state, setState] = useState({
		columns: [
			{
				title: "Image Captured",
				field: "image_captured",
				cellStyle: style,
				defaultFilter: "Y",
			},
			{ title: "Style ID", field: "style_id", cellStyle: style },
			{
				title: "Description",
				field: "description",
				cellStyle: style,
			},
			{
				title: "Category",
				field: "category_name",
				cellStyle: style,
				defaultGroupOrder: 0,
			},
			{ title: "Type", field: "product_type_name", cellStyle: style },
			{ title: "Supplier", field: "supplier_name", cellStyle: style },
			{ title: "Price", field: "original_price", cellStyle: style },
			{
				title: "Plan Season",
				field: "plan_season",
				cellStyle: style,
			},
		],
	});

	if (isLoading) return (<Icon />)

	return (
		<div>
			<MaterialTable
				title="Items"
				tableRef={tableRef}
				onFilterChange={() => {
					setItemCount(tableRef.current.dataManager.filteredData.length);
				}}
				columns={state.columns}
				data={data}
				onRowClick={(evt, selectedRow) => {
					handleOpen(true);
					setSelected({ selectedRow });
				}}
				actions={[
					{
						icon: "save_alt",
						tooltip: "Upload Picture",
						onClick: (event, rowData) => {
							setUploadModal(true);
						},
					},
				]}
				components={
					{
						Groupbar: (props) => {
							return (
								<div style={{ display: "none" }}>
									<MTableGroupbar {...props} />
								</div>
							);
						},
					}
				}
				options={{
					search: true,
					filtering: true,
					paging: false,
					actionsColumnIndex: 8,
					headerStyle: {
						textAlign: "left",
					},
					grouping: true,
				}}
			/>
		</div>
	);
};

export default Items;
