import React from "react";
import Logo from "../images/Pattern_Picture_Manager_RGB.png";
import {useRecoilState} from "recoil";
import "./Navbar.css";
import {accountID as accountIDAtom} from "./Atoms"
import { Auth } from "aws-amplify";

const Navbar = ({ setIsAuthenticating, search, setSearch }) => {

	const [accountID, setAccountID] = useRecoilState(accountIDAtom);


	async function handleLogout() {
		try {
			await Auth.signOut();
			setIsAuthenticating(true);
		} catch (error) {
			console.log("error signing out: ", error);
		}
	}

	return (
		<div className="navbar">
			<img src={Logo} className="logo" />
			<div className="search-sign-out">
				{/* <div className="navbar-search">
					<Searchbar />
				</div> */}
				<div className="sign-out-button" onClick={handleLogout}>
					{/* <h4 style={{color: "white"}}>Build Version: 2.0.0.0</h4> */}
					<h3>Logout</h3>
				</div>
			</div>
		</div>
	);
};

export default Navbar;
