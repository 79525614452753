import React, { useState } from "react";
import MaterialTable, { MTableGroupbar, MTableToolbar } from "material-table";
import { ReactComponent as Icon } from "../images/Spinner.svg";
import "./Table.css";

//From Workspace



const Table = ({
	filtering,
	handleOpen,
	setSelected,
	selected,
	styles,
	submitting,
	captured
}) => {
	const style = {
		textAlign: "left",
		fontFamily: '"Montserrat", sans-serif'
	};

	const [state, setState] = useState({
		columns: [
			{
				title: "Week Number",
				field: "week_no",
				cellStyle: style,
				defaultGroupOrder: 0, filtering: false

			},
			{
				title: "Style ID",
				field: "style_id",
				cellStyle: {
					width: "12vw",
					textAlign: "left"
				},
				headerStyle: {
					textAlign: "center"
				}, filtering: false
			},
			{
				title: "Style Description",
				field: "style_desc",
				cellStyle: style, filtering: false
			},
			{ title: "Supplier", field: "Supplier", cellStyle: { width: "10vw" }, filtering: false },
			{ title: "Order ID", field: "order_no", cellStyle: style, filtering: false },
			{ title: "Delivery Date", field: "DueDate", cellStyle: style, filtering: false },
			{ title: "Units", field: "units", cellStyle: style, filtering: false },
			{
				title: "Status",
				field: "Status",
				cellStyle: style, filtering: false
			},
			{
				title: "Picture Status",
				field: "status",
				cellStyle: style,
				render: rowData =>
					submitting ? (
						<Icon style={{ width: "30px", height: "30px" }} />
					) :
						rowData.image_captured === 'Y' ? (
							<h6 id="capturedFont">Captured</h6>
						) : (
								<h6 id="notCapturedFont">Not captured</h6>
							)
			}
		]
	});

	return (
		<div>
			<MaterialTable
				title="Worklist"
				columns={state.columns}
				data={styles}
				actions={[
					{
						icon: "save_alt",
						tooltip: "Upload Picture",
						onClick: (event, rowData) => {
							handleOpen(rowData);
						}
					}
				]}
				onRowClick={(evt, selectedRow) => {
					setSelected({ selectedRow });
					handleOpen(selectedRow);
				}}
				components={{
					Groupbar: props => {
						return (
							<div style={{ display: "none" }}>
								<MTableGroupbar {...props} />
							</div>
						);
					}
				}}
				options={{
					rowStyle: rowData => ({
						backgroundColor: selected === rowData.tableData.id ? "#EEE" : "#FFF"
					}),
					search: true,
					filtering: false,
					paging: false,
					actionsColumnIndex: 8,
					grouping: true,
					maxBodyHeight: "90vh",
					headerStyle: {
						textAlign: "left",
						position: "sticky",
						top: 0
					}
				}}
			/>
		</div>
	);
};

export default Table;
