import React, { useState } from "react";
import Workspace from "./Workspace";
import Navbar from "./Navbar";

const Authenticated = ({ authenticated, setIsAuthenticating }) => {
	const [search, setSearch] = useState("");

	return (
		<>
			<div className="navbar-container">
				<Navbar
					setIsAuthenticating={setIsAuthenticating}
					search={search}
					setSearch={setSearch}
				/>
			</div>
			<div className="workspace-container">
				<Workspace search={search} />
			</div>
		</>
	);
};

export default Authenticated;
