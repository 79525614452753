import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Upload from "./Upload";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalContainer({
	handleClose,
	open,
	selected,
	type,
	setLoading,
	submitting,
	setSubmitting,
	setCaptured,
	loading
}) {
	if (type === "item") {
		selected.style_desc = selected.selectedRow.description;
		selected.Supplier = selected.selectedRow.supplier_name;
		selected.style_id = selected.selectedRow.style_id;
	}

	return (
		<div>
			<Dialog
				fullScreen={true}
				disableScrollLock={true}
				maxWidth="xl"
				style={{ width: "100%", height: "100vh", backgroundColor: "#f1f1f1" }}
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<DialogTitle>
					<div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#efb758", borderRadius: "5px", height: "100%", padding: "10px" }}>
						{selected.style_desc} ({selected.style_id}) - {selected.Supplier}
						<div>

							<CloseRoundedIcon
								onClick={handleClose}
								style={{ cursor: "pointer" }} />

						</div>

					</div>
				</DialogTitle>
				<div>
					<Upload
						mainLoading={loading}
						setCaptured={setCaptured}
						selected={selected}
						handleClose={handleClose}
						setMainLoading={setLoading}
						submitting={submitting}
						setSubmitting={setSubmitting}
					/>
				</div>
			</Dialog>
		</div>
	);
}
