import React, { useState, useEffect } from "react";
import Login from "./components/Login/Login";
import Authenticated from "./components/Authenticated";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import "./App.css";
import * as AWS from "aws-sdk/global";
import Amplify from "aws-amplify";
import { useRecoilState } from "recoil";
import {
	federated as federatedAtom,
	idToken as idTokenAtom,
	author as authorAtom,
} from "./components/Atoms";
// import {
// 	MsalProvider,
// 	useIsAuthenticated,
// 	useMsalAuthentication,
// 	useMsal,
// 	useAccount,
// } from "@azure/msal-react";
// import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { Auth } from "aws-amplify";

export const userContext = React.createContext();


Amplify.configure({
	Auth: {
		// REQUIRED - Amazon Cognito Region
		region: "eu-west-1",

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: process.env.REACT_APP_USER_POOL_ID,

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: false,

		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		authenticationFlowType: "USER_PASSWORD_AUTH",
	},
});


function App() {
	//Cognito User
	const [idToken, setIdToken] = useRecoilState(idTokenAtom);
	const [user, setUser] = useState();

	useEffect(() => {
		onLoad();
	}, []);

	async function onLoad() {
		try {
			// await Auth.currentSession().then((data) => console.log(data));
			// const user = await Auth.createCognitoUser();
			// console.log(user);
			const user = await Auth.currentAuthenticatedUser();
			// console.log(user);
			setIsAuthenticating(false);
		} catch (e) {
			if (e !== "No current user") {
				// alert(e);
			}
		}
	}

	const { Provider } = userContext;

	const [authenticated, setAuthenticated] = useState();
	const [isAuthenticating, setIsAuthenticating] = useState(true);

	return (
		<div className="App">
				{isAuthenticating ? (
					<Login
						authenticated={authenticated}
						setAuthenticated={setAuthenticated}
						setIsAuthenticating={setIsAuthenticating}
					/>
				) : (
					<>
						<Provider value={user}>
							<Authenticated setIsAuthenticating={setIsAuthenticating} />
						</Provider>
					</>
				)}
		</div>
	);
}

export default App;
