import React, { useState, useEffect } from "react";
import Logo from "../../images/Pattern_Picture_Manager_RGB.png";
import { Input } from "semantic-ui-react";
import { ReactComponent as Icon } from "../../images/Spinner.svg";
import {
	CognitoUserPool,
	CognitoUser,
	AuthenticationDetails,
} from "amazon-cognito-identity-js";
import Forgot from "../Forgot/Forgot";
import { useRecoilState } from "recoil";
import "./Login.css";
import * as AWS from "aws-sdk/global";
import {
	useMsal,
	useAccount,
	useMsalAuthentication,
	useIsAuthenticated,
} from "@azure/msal-react";
import {
	token as tokenAtom,
	author as authorAtom,
	accountID as accountIDAtom,
	federated as federatedAtom,
} from "../Atoms";
import { Auth } from "aws-amplify";

const Login = ({ setAuthenticated, setIsAuthenticating }) => {
	const [loginState, setLoginState] = useState({
		username: "",
		password: "",
	});
	const isAuthenticated = useIsAuthenticated();

	// const {login, result, error} = useMsalAuthentication("popup");
	const [apiData, setApiData] = useState(null);
	const [token, setToken] = useRecoilState(tokenAtom);
	const [author, setAuthor] = useRecoilState(authorAtom);
	const [authenticatedUser, setAuthenticatedUser] = useState(null);


	const handleSubmit = async (e) => {
		e.preventDefault();
		if (loginState.username === "" || loginState.password === "") {
		} else setLoading(true);
		try {
			const user = await Auth.signIn(loginState.username, loginState.password);
			setAuthenticatedUser(user);
			// console.log(user);
			if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
				setLoading(false);
				// setNewPasswordRequired(true);
			}
			else {
				setLoading(false);
				setIsAuthenticating(false);
			}
		} catch (error) {
			alert(error.message);
			setLoading(false);
			console.log("error signing in", error);
		}
	};

	const [forgotPW, setForgotPW] = useState(false);

	const [incorrect, setIncorrect] = useState(false);

	const [loading, setLoading] = useState(false);

	const handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		setLoginState((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const buttonsStyle = {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-evenly",
		width: "100%",
		marginTop: "20px",
	};

	return (
		<>
			{!forgotPW ? (
				<form className="login">
					<img className="login-logo" src={Logo} alt="login-logo" />
					<div className="login-container">
						<h3>Sign in to your account</h3>
						<h4>
							Email <span style={{ color: "#efb758" }}>*</span>
						</h4>
						<Input
							placeholder="Email..."
							name="username"
							value={loginState.email}
							id="login-email"
							onChange={handleChange}
							required
						/>
						<h4>
							Password <span style={{ color: "#efb758" }}>*</span>
						</h4>
						<Input
							name="password"
							type="password"
							placeholder="Enter your password..."
							value={loginState.password}
							id="login-password"
							onChange={handleChange}
							required
						/>

						{incorrect ? (
							<h6 className="incorrect">
								The username or password you have entered is incorrect
							</h6>
						) : null}
						<h6 className="forgot" onClick={() => setForgotPW(true)}>
							Forgot password? Reset password
						</h6>
						<div className="login-create">
								<div style={buttonsStyle}>
									<button
										onClick={handleSubmit}
										className="sign-in-button hvr-shutter-in-vertical"							>
										{loading? "SIGNING IN": "SIGN IN"}
									</button>
								</div>
						</div>
					</div>
				</form>
			) : (
				<Forgot forgotPW={forgotPW} setForgotPW={setForgotPW} />
			)}
		</>
	);
};

export default Login;
