import React, { useState, useContext, useEffect } from "react";
import MaterialTable, { MTableGroupbar, MTablePagination, MTableToolbar } from "material-table";
import { ReactComponent as Icon } from "../images/Spinner.svg";
import axios from "axios";
import { userContext } from "../App";
import { useQuery } from "react-query";
import { Auth } from "aws-amplify";

const Log = () => {

    const [log, setLog] = useState([]);
    const style = {
        // textAlign: "left",
        // fontSize: "1.5rem"
    };
    // const [data, setData] = useState([]);
    // const [loading, setLoading] = useState(true);

    async function getData() {
        const user = await Auth.currentAuthenticatedUser();
        const idToken = user.signInUserSession.idToken.jwtToken;
        const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}log`,
            {
                headers: {
                    Authorization: idToken
                }
            });
        console.log(response);
        setLog(response.data);
        return response.data;
    }

    const { data, isLoading, error } = useQuery("pics", getData);

    // useEffect(() => {
    //     async function getData() {
    //         const response = await axios.get("https://7idptikmr4.execute-api.eu-west-1.amazonaws.com/dev/pictures",
    //             {
    //                 headers: {
    //                     Authorization: auth
    //                 }
    //             });
    //         console.log(response);
    //         setData(response.data);
    //         setLoading(false);
    //     }
    //     getData();
    // }, []);

    const [state, setState] = useState({
        columns: [
            {
                title: "Style", field: "style_id", cellStyle: style
            },
            {
                title: "Revision", field: "revision", cellStyle: style
            },
            {
                title: "Revision Date", field: "revision_date", cellStyle: style
            },
            {
                title: "Author", field: "author", cellStyle: style, defaultGroupOrder: 0
            },
            {
                title: "Image",
                field: "url",
                cellStyle: style,

                render: rowData => (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <img
                            src={rowData.url}
                            alt="id"
                            style={{ width: 30, margin: 0, padding: 0, height: "auto" }}
                        />

                    </div>

                )
            }
        ]
    });

    if (isLoading) return (<Icon />)

    return (
        <div>
            <MaterialTable
                title="Pictures"
                columns={state.columns}
                data={log}
                components={{
					Groupbar: props => {
						return (
							<div style={{ display: "none" }}>
								<MTableGroupbar {...props} />
							</div>
						);
					}
				}}

                options={{

                    toolbar: true,
                    search: true,
                    paging: false,
                    paginationType: "stepped",
                    actionsColumnIndex: 8,
                    grouping: false,
                    headerStyle: {
                        textAlign: "left",
                        position: 'sticky', top: 0
                    },
                    maxBodyHeight: "90vh",
                    cellStyle: {
                        textAlign: "center",
                        height: "5vh"
                    }
                }}
            />





        </div>
    );
};

export default Log;
