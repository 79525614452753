import React, { useState } from "react";
import {
	CognitoUserPool,
	CognitoUser,
	AuthenticationDetails
} from "amazon-cognito-identity-js";
import Logo from "../../images/Pattern_Picture_Manager_RGB.png";
import { Input } from "semantic-ui-react";
import "../Login/Login.css";
import { ReactComponent as Icon } from "../../images/Spinner.svg";

const Forgot = ({ forgotPW, setForgotPW }) => {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [codeSent, setCodeSent] = useState(false);
	const [code, setCode] = useState("");
	const [newPassword, setNewPassword] = useState("");

	const handleChange = e => {
		e.preventDefault();
		if (e.target.name === "username") {
			setEmail(e.target.value);
		}
		if (e.target.name === "code") {
			setCode(e.target.value);
		}
		if (e.target.name === "password") {
			setNewPassword(e.target.value);
		}
	};

	var poolData = {
		UserPoolId: "eu-west-1_BWr8lTmEc", // Your user pool id here
		ClientId: "3j353kho5dcpj4b6bb7nglvrd0" // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);

	const userData = {
		Username: email,
		Pool: userPool
	};
	const cognitoUser = new CognitoUser(userData);

	const handlePasswordChange = e => {
		setLoading(true);
		e.preventDefault();
		cognitoUser.confirmPassword(code, newPassword, {
			onSuccess() {
				console.log("Password confirmed!");
				setLoading(false);
				setCodeSent(false);
				setForgotPW(false);
			},
			onFailure(err) {
				console.log("Password not confirmed!");
				console.log(err.message);
				if (
					err.message.includes(
						"Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6"
					)
				) {
					alert("Member must have length greater than or equal to 6");
				}

				if (
					err.message ===
					"Invalid verification code provided, please try again."
				) {
					alert("Invalid verification code provided, please try again.");
				}
			}
		});
	};

	const handleForgot = e => {
		e.preventDefault();
		setLoading(true);
		cognitoUser.forgotPassword({
			onSuccess: function (data) {
				setCodeSent(true);
				// successfully initiated reset password request
				setLoading(false);
			},
			onFailure: function (err) {
				alert(err.message || JSON.stringify(err));
			}
			//Optional automatic callback
			// inputVerificationCode: function(data) {
			//     console.log('Code sent to: ' + data);
			//     cognitoUser.confirmPassword(verificationCode, newPassword, {
			//         onSuccess() {
			//             console.log('Password confirmed!');
			//         },
			//         onFailure(err) {
			//             console.log('Password not confirmed!');
			//         },
			//     });
			// },
		});
	};

	return (
		<div>
			<form className="login">
				<img className="login-logo" src={Logo} alt="login-logo" />
				<div className="login-container">
					{!codeSent ? (
						<>
							<h3>Forgot password</h3>
							<h4>
								Please enter your email address
								<span style={{ color: "#efb758" }}>*</span>
							</h4>
							<Input
								placeholder="Email..."
								name="username"
								value={email}
								id="forgot-email"
								onChange={handleChange}
								required
							/>
							<div className="forgot-send-button">
								{!loading ? (
									<button
										// onClick={handleClick}
										className="sign-in-button hvr-shutter-in-vertical"
										onClick={handleForgot}
									>
										SEND CODE
									</button>
								) : (
										<Icon className="spinner" />
									)}
							</div>
						</>
					) : (
							<>
								<h3>Forgot password</h3>
								<h4>
									Enter verification code
								<span style={{ color: "#efb758" }}>*</span>
								</h4>
								<Input
									placeholder="Code..."
									name="code"
									value={code}
									id="login-email"
									onChange={handleChange}
									required
								/>
								<h4>
									Enter new password
								<span style={{ color: "#efb758" }}>*</span>
								</h4>
								<Input
									placeholder="New password..."
									name="password"
									type="password"
									value={newPassword}
									id="login-email"
									onChange={handleChange}
									required
								/>
								<div className="forgot-send-button">
									{!loading ? (
										<button
											// onClick={handleClick}
											className="sign-in-button hvr-shutter-in-vertical"
											onClick={handlePasswordChange}
										>
											SUBMIT
										</button>
									) : (
											<Icon className="spinner" />
										)}
								</div>
							</>
						)}
				</div>
			</form>
		</div>
	);
};

export default Forgot;
