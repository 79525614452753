import { atom } from "recoil";

export const uploadModal = atom({
	key: "uploadModal",
	default: false,
});

export const itemCount = atom({
	key: "itemCount",
	default: "",
});

export const front = atom({
	key: "front",
	default: {},
});
export const back = atom({
	key: "back",
	default: {},
});
export const trim = atom({
	key: "trim",
	default: {},
});
export const misc = atom({
	key: "misc",
	default: {},
});
export const misc2 = atom({
	key: "misc2",
	default: {},
});

export const images = atom({
	key: "images",
	default: [],
});

export const federated = atom({
	key: "federated",
	default: false,
});

export const token = atom({
	key: "token",
	default: "",
});

export const author = atom({
	key: "author",
	default: "",
});

export const accountID = atom({
	key: "accountID",
	default: "",
});
export const idToken = atom({
	key: "idToken",
	default: "",
});
