import React, {
	useState,
	useEffect,
	useContext,
	useRef
} from "react";
import Dropzone from "./Dropzone";
import {
	token as tokenAtom,
	author as authorAtom,
	images as imagesAtom,
	front as frontAtom,
	back as backAtom,
	trim as trimAtom,
	misc as miscAtom,
	misc2 as misc2Atom,
} from "./Atoms";
import axios from "axios";
import { useRecoilState } from "recoil";
import "./Upload.css";
import { userContext } from "../App";
import { ReactComponent as Svg } from "../images/Spinner.svg";
import { makeStyles, StylesProvider } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import {
	Select,
	Button,
	Icon,
	Segment,
	Divider,
	Form,
	Label,
	Popup,
} from "semantic-ui-react";
import {
	CarouselProvider,
	Slider,
	Slide,
	Image,
} from "pure-react-carousel";
// import ContentEditable from "react-contenteditable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import EditableLabel from "react-inline-editing";
import Editable from "./Editable";
import Reordering from "./Reordering";
import ImageGallery from "react-image-gallery";
import { useQuery } from "react-query";
import _ from "lodash";
// import InlineEdit from 'react-edit-inline';
import "pure-react-carousel/dist/react-carousel.es.css";
import { Auth } from "aws-amplify";

const useStyles = makeStyles({
	root: {
		minWidth: 275,
		minHeight: "50vh",
		translate: "transformY(10vh)",
	},
	accordion: {
		width: "100%",
	},

	bullet: {
		display: "inline-block",
		margin: "0 2px",
		transform: "scale(0.8)",
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

const Upload = ({
	selected,
	setMainLoading,
	submitting,
	setSubmitting,
	setCaptured,
	handleClose,
}) => {
	const classes = useStyles();
	//Files = the image that is dragged onto the dropzone component, which is
	//then displayed in the picture container
	const [reordering, setReordering] = useState(false);
	const [list, setList] = useState([]);
	const [mount, setMount] = useState();
	const [activeIndex, setActiveIndex] = useState(0);
	const [files, setFiles] = useState([]);
	const [make, setMake] = useState("");
	const [submitted, setSubmitted] = useState(true);
	const [submittedUrl, setSubmittedUrl] = useState("");
	const [styleData, setStyleData] = useState([]);
	const [revisionData, setRevisionData] = useState([]);
	const [loading, setLoading] = useState(true);
	// const [images, setImages] = useState([]);
	const [latestRevision, setLatestRevision] = useState();
	const [currentSlide, setCurrentSlide] = useState(0);
	const [totalSlides, setTotalSlides] = useState(4);
	const [frontAngle, setFrontAngle] = useState("");
	const [backAngle, setBackAngle] = useState("");
	const [trimAngle, setTrimAngle] = useState("");
	const [miscAngle, setMiscAngle] = useState("");
	const [misc2Angle, setMisc2Angle] = useState("");
	const [frontRevision, setFrontRevision] = useState(1);
	const [backRevision, setBackRevision] = useState(1);
	const [trimRevision, setTrimRevision] = useState(1);
	const [miscRevision, setMiscRevision] = useState(1);
	const [misc2Revision, setMisc2Revision] = useState(1);
	const [frontRevisionDate, setFrontRevisionDate] = useState("");
	const [backRevisionDate, setBackRevisionDate] = useState("");
	const [miscRevisionDate, setMiscRevisionDate] = useState("");
	const [misc2RevisionDate, setMisc2RevisionDate] = useState("");
	const [trimRevisionDate, setTrimRevisionDate] = useState("");
	const [frontAuthor, setFrontAuthor] = useState("");
	const [backAuthor, setBackAuthor] = useState("");
	const [miscAuthor, setMiscAuthor] = useState("");
	const [misc2Author, setMisc2Author] = useState("");
	const [trimAuthor, setTrimAuthor] = useState("");
	const [currentCapturedDate, setCurrentCapturedDate] = useState("");
	const [currentAngle, setCurrentAngle] = useState("Front");
	const [currentRevision, setCurrentRevision] = useState(0);
	const [currentAuthor, setCurrentAuthor] = useState("");
	const [miscRevisions, setMiscRevisions] = useState([]);
	const [revisionsExist, setRevisionsExist] = useState(false);
	const [revisionsAvailable, setRevisionsAvailable] = useState([]);

	const [revisionSelect, setRevisionSelect] = useState([]);
	const [miscRevisionSelect, setMiscRevisionSelect] = useState([]);
	const [misc2RevisionSelect, setMisc2RevisionSelect] = useState([]);
	const [trimRevisionSelect, setTrimRevisionSelect] = useState([]);
	const [backRevisionSelect, setBackRevisionSelect] = useState([]);
	const [frontRevisionSelect, setFrontRevisionSelect] = useState([]);

	const [miscDescription, setMiscDescription] = useState();
	const [misc2Description, setMisc2Description] = useState();
	const [trimDescription, setTrimDescription] = useState();
	const [backDescription, setBackDescription] = useState();
	const [frontDescription, setFrontDescription] = useState("");
	const [currentDescription, setCurrentDescription] = useState("");

	const [descEditable, setDescEditable] = useState(true);

	const [revisionChange, setRevisionChange] = useState([]);

	const [images, setImages] = useRecoilState(imagesAtom);

	const [miscArray, setMiscArray] = useState([]);
	const [frontArray, setFrontArray] = useState([]);
	const [backArray, setBackArray] = useState([]);
	const [trimArray, setTrimArray] = useState([]);
	const [misc2Array, setMisc2Array] = useState([]);

	const [currentURL, setCurrentURL] = useState("");

	const [prodDescription, setProdDescription] = useState("");

	const [modalExpanded, setModalExpanded] = useState(false);

	const [uploading, setUploading] = useState(false);

	const [copied, setCopied] = useState(false);

	const [revisions, setRevisions] = useState([]);

	const gallery = useRef();

	// const [makes, setMakes] = useState([
	// 	{ key: 'front', value: 'front', text: '01' },
	// 	{ key: 'back', value: 'back', text: '02' },
	// 	{ key: 'trim', value: 'trim', text: '03' },
	// 	{ key: 'misc', value: 'misc', text: '04' },
	// 	{ key: 'misc2', value: 'misc2', text: '05' }
	// ]);

	const [makes, setMakes] = useState([
		{ key: "01", value: "01", text: "01" },
		{ key: "02", value: "02", text: "02" },
		{ key: "03", value: "03", text: "03" },
		{ key: "04", value: "04", text: "04" },
		{ key: "05", value: "05", text: "05" },
	]);

	function handleMake(make) {
		setMake(make);
	}

	function handleBackClick() {
		console.log(gallery.getCurrentIndex());
		if (currentSlide === 0) {
		} else setCurrentSlide(currentSlide - 1);
		setCopied(false);
	}

	function handleNextClick() {
		console.log(gallery.getCurrentIndex());
		if (currentSlide === totalSlides) {
		} else setCurrentSlide(currentSlide + 1);
		setCopied(false);
	}

	/*************************************************** */

	const [token, setToken] = useRecoilState(tokenAtom);
	const [federatedAuthor, setFederatedAuthor] = useRecoilState(authorAtom);

	const user = useContext(userContext);

	//Cognito token for authorization
	let auth, author;
	try {
		auth = user.signInUserSession.idToken.jwtToken;
		author = user.signInUserSession.idToken.payload.email;
	} catch {
		auth = token;
		author = federatedAuthor;
	}
	const status = selected.status;

	let style = selected.style_id;
	//style id passed on to GET request to fetch data of specific style
	let id = selected.style_id;

	const [selectedMake, setSelectedMake] = useState("");

	/************************************************* */

	function makeAngle(angle) {
		let make;
		switch (angle) {
			case "01":
				make = "front";
				break;
			case "02":
				make = "back";
				break;
			case "03":
				make = "trim";
				break;
			case "04":
				make = "misc";
				break;
			case "05":
				make = "misc2";
				break;
			default:
				make = "01";
				break;
		}

		return make;
	}

	const noImage = <Svg />;
	const text = useRef();

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			getInfo();
		}

		return () => (mounted = false);
	}, [uploading]);

	const data = useQuery("data", getInfo);
	//Getting urls for each angle

	// const data = useQuery('data', getInfo);

	const tempImages = [
		{
			original: noImage,
			thumbnail: noImage,
		},
	];

	async function getAngles(styleData) {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		const revisions = await axios.get(
			`${process.env.REACT_APP_ENDPOINT}revisions/${id}`,
			{
				headers: {
					Authorization: idToken,
				},
			}
		);

		setRevisions(revisions.data);

		console.log(styleData);
		// console.log(sorted);

		if (styleData.length > 0) {
			setCurrentRevision(styleData[0].revision);
			setCurrentAuthor(styleData[0].author);
			setCurrentCapturedDate(styleData[0].revision_date);
			setCurrentDescription(styleData[0].style_web_description);
		}

		let im = styleData.map((i, index) => {
			return {
				original: i.url,
				thumbnail: i.url,
				angle: i.picture_angle,
				originalClass: "image-class",
				style_image_id: i.style_image_id,
			};
		});

		let i = _.sortBy(im, (o) => Number(o.angle));
		// let i = im.reverse();
		setImages(i);

		let front = styleData.filter((i) => {
			return i.picture_angle === "01";
		});

		if (front.length > 0) {
			setFrontAngle(front[0].url);
			setFrontDescription(front[0].style_web_description);
			setFrontRevisionDate(front[0].revision_date);
			setFrontAuthor(front[0].author);
			setFrontRevision(front[0].revision);
			// setFront({
			// 	url: front[0].url,
			// 	description: front[0].style_web_description,
			// 	author: front[0].author,
			// 	revision: front[0].revision,
			// 	name: "front"
			// })
			// setCurrentRevision(front[0].revision);
			// setCurrentAuthor(front[0].author);
			// setCurrentCapturedDate(front[0].revision_date);
			// setCurrentDescription(front[0].style_web_description);
			if (frontRevision > 0) {
				setRevisionsExist(true);
				let frontR = revisions.data.filter((i) => {
					return i.picture_angle === "01";
				});
				setFrontArray(frontR);
				let revisionSelect = frontR.map((date) => {
					return {
						revision_date: date.revision_date,
						revision: date.revision,
						author: date.author,
						description: date.style_web_description,
					};
				});

				let frontSelectOptions = revisionSelect.map((i) => {
					return { key: i.revision, value: i.revision, text: i.revision };
				});
				setRevisionSelect(frontSelectOptions);
				setFrontRevisionSelect(frontSelectOptions);
				setMount(true);
			}
		} else {
			setFrontAngle(noImage);
		}

		let back = styleData.filter((i) => {
			return i.picture_angle === "02";
		});
		if (back.length > 0) {
			setBackAngle(back[0].url);
			setBackRevisionDate(back[0].revision_date);
			setBackAuthor(back[0].author);
			setBackRevision(back[0].revision);
			setBackDescription(back[0].style_web_description);
			// setBack({
			// 	url: front[0].url,
			// 	description: back[0].style_web_description,
			// 	author: back[0].author,
			// 	revision: back[0].revision,
			// 	name: "back"
			// })
			if (backRevision > 0) {
				setRevisionsExist(true);
				let backR = revisions.data.filter((i) => {
					return i.picture_angle === "02";
				});
				setBackArray(backR);
				let revisionSelect = backR.map((date) => {
					return { revision_date: date.revision_date, revision: date.revision };
				});
				let backSelectOptions = revisionSelect.map((i) => {
					return { key: i.revision, value: i.revision, text: i.revision };
				});
				setBackRevisionSelect(backSelectOptions);
			}
		} else {
			setBackAngle("http://d5vrniv3qpnwo.cloudfront.net/no-image-found.jpg");
		}

		let trim = styleData.filter((i) => {
			return i.picture_angle === "03";
		});

		if (trim.length > 0) {
			setTrimAngle(trim[0].url);
			setTrimRevisionDate(trim[0].revision_date);
			setTrimAuthor(trim[0].author);
			setTrimRevision(trim[0].revision);
			setTrimAngle(trim[0].url);
			setTrimRevisionDate(trim[0].revision_date);
			setTrimAuthor(trim[0].author);
			setTrimRevision(trim[0].revision);
			setTrimDescription(trim[0].style_web_description);
			// setTrim({
			// 	url: trim[0].url,
			// 	description: trim[0].style_web_description,
			// 	author: trim[0].author,
			// 	revision: trim[0].revision,
			// 	name: "trim"
			// })
			if (trimRevision > 0) {
				setRevisionsExist(true);
				let trimR = revisions.data.filter((i) => {
					return i.picture_angle === "03";
				});
				setTrimArray(trimR);
				let revisionSelect = trimR.map((date) => {
					return { revision_date: date.revision_date, revision: date.revision };
				});
				let trimSelectOptions = revisionSelect.map((i) => {
					return { key: i.revision, value: i.revision, text: i.revision };
				});
				setTrimRevisionSelect(trimSelectOptions);
			}
		} else {
			setTrimAngle("http://d5vrniv3qpnwo.cloudfront.net/no-image-found.jpg");
		}

		let misc = styleData.filter((i) => {
			return i.picture_angle === "04";
		});

		if (misc.length > 0) {
			setMiscAngle(misc[0].url);
			setMiscRevisionDate(misc[0].revision_date);
			setMiscAuthor(misc[0].author);
			setMiscRevision(misc[0].revision);
			setMiscDescription(misc[0].style_web_description);
			// setMisc({
			// 	url: misc[0].url,
			// 	description: misc[0].style_web_description,
			// 	author: misc[0].author,
			// 	revision: misc[0].revision,
			// 	name: "misc"
			// })
			if (miscRevision > 0) {
				setRevisionsExist(true);
				let miscR = revisions.data.filter((i) => {
					return i.picture_angle === "04";
				});
				setMiscArray(miscR);
				let revisionSelect = miscR.map((date) => {
					return { revision_date: date.revision_date, revision: date.revision };
				});
				let miscSelectOptions = revisionSelect.map((i) => {
					return { key: i.revision, value: i.revision, text: i.revision };
				});
				setMiscRevisionSelect(miscSelectOptions);
			}
		} else {
			setMiscAngle("http://d5vrniv3qpnwo.cloudfront.net/no-image-found.jpg");
		}

		let misc2 = styleData.filter((i) => {
			return i.picture_angle === "05";
		});

		if (misc2.length > 0) {
			setMisc2Angle(misc2[0].url);
			setMisc2RevisionDate(misc2[0].revision_date);
			setMisc2Author(misc2[0].author);
			setMisc2Revision(misc2[0].revision);
			setMisc2Description(misc2[0].style_web_description);
			// setMisc2({
			// 	url: misc2[0].url,
			// 	description: misc2[0].style_web_description,
			// 	author: misc2[0].author,
			// 	revision: misc2[0].revision,
			// 	name: "misc2"
			// })
			if (misc2Revision > 0) {
				setRevisionsExist(true);
				let misc2R = revisions.data.filter((i) => {
					return i.picture_angle === "05";
				});
				setMisc2Array(misc2R);
				let revisionSelect = misc2R.map((date) => {
					return { revision_date: date.revision_date, revision: date.revision };
				});
				let misc2SelectOptions = revisionSelect.map((i) => {
					return { key: i.revision, value: i.revision, text: i.revision };
				});
				setMisc2RevisionSelect(misc2SelectOptions);
			}
		} else {
			setMisc2Angle("http://d5vrniv3qpnwo.cloudfront.net/no-image-found.jpg");
		}

		setList([frontAngle, backAngle, trimAngle, miscAngle, misc2Angle]);
	}

	/******************************************** */

	const notFoundURL = `http://d5vrniv3qpnwo.cloudfront.net/no-image-found.jpg`;

	function getNotFoundPic() {
		return <Svg />;
	}

	async function getInfo() {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		await axios
			.get(`${process.env.REACT_APP_ENDPOINT}info/${id}`, {
				headers: {
					Authorization: idToken,
				},
			})
			.then((res) => {
				if (res.data.length === 0) {
					console.log(res);
					setLoading(false);
				} else {
					console.log(res.data);
					setSubmitted(true);
					setStyleData(res.data);
					getAngles(res.data);
					// setMake("front");
					setLoading(false);
					return res.data;
				}
			});
	}

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			getInfo();
		}
		return () => {
			setImages([]);
			setSubmitted(false);
			setStyleData([]);
			mounted = false;
		};
	}, [style]);

	const setStylesFunc = (data) => {
		getAngles(data);
	};

	function openModal() {
		setModalExpanded(!modalExpanded);
		setCurrentRevision((prevRevision) => prevRevision + 1);
		setDescEditable(false);
	}

	function changeRevision(e) {
		let revision_change = [...revisionChange];
		revision_change.push({ slide: currentSlide, revision: e.target.innerText });
		setRevisionChange(revision_change);

		let slide = `0${currentSlide + 1}`;
		let revisionList = revisions.filter((r) => {
			return r.picture_angle === slide;
		});

		let revisionSelect = revisionList.map((date) => {
			return {
				revision_date: date.revision_date,
				revision: date.revision,
				author: date.author,
				description: date.style_web_description,
			};
		});

		let revision = revisions.filter((r) => {
			return r.revision === e.target.innerText && r.picture_angle === slide;
		});
		let im = [...images];
		im[currentSlide] = {
			original: revision[0].url,
			thumbnail: revision[0].url,
			angle: revision[0].picture_angle,
			style_image_id: revision[0].style_image_id,
			originalClass: "image-class",
		};
		setImages(im);
		setCurrentDescription(revision[0].style_web_description);
		setCurrentCapturedDate(revision[0].revision_date);
		setCurrentRevision(revision[0].revision);
	}

	/***************************************************************************** */

	function setup() {
		setCurrentCapturedDate(frontRevisionDate);
		setCurrentAuthor(frontAuthor);
		console.log(frontAuthor);
		setCurrentRevision(frontRevision);
		setCurrentAngle("Front");
		setRevisionSelect(frontRevisionSelect);
		setCurrentDescription(frontDescription);
		setMake("front");
		setCurrentURL(frontAngle);
	}

	useEffect(() => {
		if (revisionChange.filter((f) => f.slide === currentSlide).length > 0) {
			let latest = revisionChange.filter((k) => {
				return currentSlide === k.slide;
			});
			let latestRevision = Number(latest[0].revision) - 1;
			let slide = `0${currentSlide + 1}`;
			let revisionList = revisions.filter((r) => {
				return r.picture_angle === slide;
			});
			console.log("Change");

			let revision = revisions.filter((r) => {
				return r.picture_angle === slide;
			});
			let selectOptions = revisionList.map((i) => {
				return { key: i.revision, value: i.revision, text: i.revision };
			});
			console.log(latestRevision);
			console.log(revision);
			setCurrentDescription(revision[latestRevision].style_web_description);
			setCurrentCapturedDate(revision[latestRevision].revision_date);
			setCurrentRevision(revision[latestRevision].revision);
			setCurrentAngle(revision[latestRevision].picture_angle);
			setRevisionSelect(selectOptions);
			setMake(revision[latestRevision].picture_angle);
			return;
		} else if (styleData.length > 0) {
			setCurrentCapturedDate(styleData[currentSlide].revision_date);
			setCurrentAuthor(styleData[currentSlide].author);
			setCurrentRevision(styleData[currentSlide].revision);
			setCurrentAngle(makeAngle(styleData[currentSlide].picture_angle));
			setMake(styleData[currentSlide].picture_angle.toLowerCase());
			setCurrentDescription(styleData[currentSlide].style_web_description);
			setCurrentURL(styleData[currentSlide].url);

			if (styleData[currentSlide].picture_angle === "01") {
				setRevisionSelect(frontRevisionSelect);
			} else if (styleData[currentSlide].picture_angle === "02") {
				setRevisionSelect(backRevisionSelect);
			} else if (styleData[currentSlide].picture_angle === "03") {
				setRevisionSelect(trimRevisionSelect);
			} else if (styleData[currentSlide].picture_angle === "04") {
				setRevisionSelect(miscRevisionSelect);
			} else if (styleData[currentSlide].picture_angle === "05") {
				setRevisionSelect(misc2RevisionSelect);
			}
		}
	}, [currentSlide]);

	useEffect(() => {
		if (styleData.length > 0) {
			setCurrentCapturedDate(styleData[currentSlide].revision_date);
			setCurrentAuthor(styleData[currentSlide].author);
			setCurrentRevision(styleData[currentSlide].revision);
			setCurrentAngle(makeAngle(styleData[currentSlide].picture_angle));
			setMake(styleData[currentSlide].picture_angle.toLowerCase());
			setCurrentDescription(styleData[currentSlide].style_web_description);
			setCurrentURL(styleData[currentSlide].url);

			if (styleData[currentSlide].picture_angle === "01") {
				setRevisionSelect(frontRevisionSelect);
			} else if (styleData[currentSlide].picture_angle === "02") {
				setRevisionSelect(backRevisionSelect);
			} else if (styleData[currentSlide].picture_angle === "03") {
				setRevisionSelect(trimRevisionSelect);
			} else if (styleData[currentSlide].picture_angle === "04") {
				setRevisionSelect(miscRevisionSelect);
			} else if (styleData[currentSlide].picture_angle === "05") {
				setRevisionSelect(misc2RevisionSelect);
			}
		}
	}, []);

	/********************************** */

	const [copySuccess, setCopySuccess] = useState("");
	const textAreaRef = useRef(null);

	const handleDescriptionChange = (evt) => {
		text.current = evt.target.value;
		setCurrentDescription(evt.target.value);
		setProdDescription(text.current);
	};

	function dataChanged(data) {
		// data = { description: "New validated text comes here" }
		// Update your model from here
		console.log(data);
		this.setState({ ...data });
	}

	function customValidateText(text) {
		return text.length > 0 && text.length < 64;
	}

	function handleSlide(currentIndex) {
		setCurrentSlide(currentIndex);
	}

	return (
		<div>
			{loading ? (
				<Svg />
			) : (
				<div className="upload">
					{!submitted ? (
						<>
							<div>
								<div className="grid">
									<div>
										<CarouselProvider
											// naturalSlideWidth={3000}
											// naturalSlideHeight={2000}
											isIntrinsicHeight
											totalSlides={totalSlides}
											currentSlide={currentSlide}
											visibleSlides={5}
										>
											<Slider>
												<Slide index={0}>
													<Image
														src={noImage}
														renderLoading={getNotFoundPic}
														renderError={notFoundURL}
													/>
												</Slide>
												<Slide index={1}>
													<Image
														src={noImage}
														renderLoading={getNotFoundPic}
														renderError={notFoundURL}
													/>
												</Slide>
												<Slide index={2}>
													<Image
														src={noImage}
														renderLoading={getNotFoundPic}
														renderError={notFoundURL}
													/>
												</Slide>
												<Slide index={3}>
													<Image
														src={noImage}
														renderLoading={getNotFoundPic}
														renderError={notFoundURL}
													/>
												</Slide>
												<Slide index={4}>
													<Image
														src={noImage}
														renderLoading={getNotFoundPic}
														renderError={notFoundURL}
													/>
												</Slide>
											</Slider>
											<Segment inverted>Captured Date: {}</Segment>
											<Button
												content="Back"
												icon="left arrow"
												labelPosition="left"
												onClick={handleBackClick}
											/>
											<Button
												content="Next"
												icon="right arrow"
												labelPosition="right"
												onClick={handleNextClick}
											/>
											<Segment inverted>Captured By: </Segment>
											<Segment inverted>Revision: </Segment>
										</CarouselProvider>
									</div>
								</div>
								<Divider vertical />
								<div className="photo-upload-container">
									<Select
										placeholder="Select angle"
										value={make}
										options={makes}
										onChange={(e) =>
											handleMake(e.target.innerText.toLowerCase())
										}
									/>
									<Dropzone
										styleData={styleData}
										submitted={submitted}
										style={style}
										setCaptured={setCaptured}
										setSubmitted={setSubmitted}
										setSubmittedUrl={setSubmittedUrl}
										submittedUrl={submittedUrl}
										handleClose={openModal}
										id={selected.style_id}
										make={make}
										setMake={setMake}
										files={files}
										setFiles={setFiles}
										selected={selected}
										setLoading={setLoading}
										setMainLoading={setMainLoading}
										submitting={submitting}
										setSubmitting={setSubmitting}
										getAnglesFunc={getAngles}
										id={id}
										setStylesFunc={setStylesFunc}
									/>
								</div>
							</div>
						</>
					) : (
						<>
							<div>
								{reordering ? (
									<Reordering
										gallery={gallery}
										revisions={revisions}
										images={images}
										setStylesFunc={setStylesFunc}
										id={id}
										setLoading={setLoading}
										setReordering={setReordering}
									/>
								) : (
									<div  className="container">
										<div className="slider">
											<ImageGallery
												thumbnailPosition="left"
												ref={gallery}
												showPlayButton={false}
												showIndex={true}
												onSlide={handleSlide}
												items={images}
											/>
											<div
												className="reorder-button"
												style={{ alignSelf: "flex-end", marginTop: "10px" }}
											>
												<Button onClick={() => setReordering(true)}>
													Re-order
												</Button>
											</div>
										</div>

										<Paper>
											<div className="info">
												<div>
													<div
														style={{
															display: "flex",
															justifyContent: "space-between",
														}}
													>
														<Label pointing="below">Revision</Label>
													</div>
													<Form style={{ width: "100%", marginBottom: "10px" }}>
														<Form.Field>
															<Select
																placeholder="Revision"
																style={{ marginTop: "20px" }}
																value={currentRevision}
																onChange={(e) => changeRevision(e)}
																placeholder="Select revision"
																options={revisionSelect}
															/>
														</Form.Field>
													</Form>
												</div>
												<div>
													<div
														style={{
															display: "flex",
															justifyContent: "space-between",
														}}
													>
														<Label pointing="below">Picture Angle </Label>
													</div>
													<Form style={{ width: "100%", marginBottom: "10px" }}>
														<Form.Field>
															<Select
																placeholder="Select angle"
																value={make}
																options={makes}
																onChange={(e) =>
																	setMake(e.target.innerText.toLowerCase())
																}
															/>
														</Form.Field>
													</Form>
												</div>
												<div style={{ marginBottom: "10px" }}>
													<Label pointing="below">Description</Label>
													<Segment size="small">
														<Editable
															text={currentDescription}
															placeholder="Product Description"
															type="textarea"
														>
															<textarea
																style={{ width: "100%" }}
																name="description"
																rows="5"
																value={currentDescription}
																onChange={(e) => handleDescriptionChange(e)}
															/>
														</Editable>
													</Segment>
												</div>
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														marginTop: "10px",
														alignItems: "center",
													}}
												>
													<Popup
														trigger={
															<CopyToClipboard text={currentURL}>
																<Button onClick={() => setCopied(true)}>
																	<Button.Content>
																		<Icon name="copy" />
																	</Button.Content>
																</Button>
															</CopyToClipboard>
														}
														content={"Copy URL"}
														inverted
													/>
												</div>
												<div style={{ marginTop: "10px" }}>
													<Segment inverted>
														Captured By: {currentAuthor}{" "}
													</Segment>
												</div>
												<div style={{ marginTop: "10px" }}>
													<Segment inverted>
														Capture Date: {currentCapturedDate}{" "}
													</Segment>
												</div>
												<div className="photo-upload-container">
													<div style={{ flex: 1, alignItems: "center" }}>
														<div>
															<Dropzone
																gallery={gallery}
																styleData={styleData}
																submitted={submitted}
																style={style}
																setCaptured={setCaptured}
																setSubmitted={setSubmitted}
																setSubmittedUrl={setSubmittedUrl}
																submittedUrl={submittedUrl}
																handleClose={openModal}
																id={selected.style_id}
																make={make}
																setMake={setMake}
																files={files}
																setFiles={setFiles}
																selected={selected}
																setLoading={setLoading}
																setMainLoading={setMainLoading}
																submitting={submitting}
																setSubmitting={setSubmitting}
																setStylesFunc={setStylesFunc}
																prodDescription={prodDescription}
																setUploading={setUploading}
																openModal={openModal}
																handleClose={handleClose}
															/>
														</div>
													</div>
												</div>
											</div>
										</Paper>
									</div>
								)}
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default Upload;
