import React, {useState, useEffect, useContext} from 'react'
import { Card, Image as CardImage } from 'semantic-ui-react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from "axios";
import "./Dropzone.css"
import {useMutation, queryCache} from "react-query";
import _ from 'lodash';
import { Auth } from "aws-amplify";

import Image from "./Image"



const Reordering = ({revisions, images, setStylesFunc, setReordering, setLoading, id}) => {
    console.log(images);
    console.log(revisions);
    const [boxes, setBoxes] = useState([])

    useEffect(() => {
        // console.log(images);

        let rev = _.sortBy(revisions, r => Number(r.picture_angle));

        console.log(rev);
        
        let list = rev.map((l, index) => {
            return {
                id: index + 1,
                order: index + 1,
                url: l.url,
                revision: l.revision,
                picture_angle: l.picture_angle,
                description: l.style_web_description,
                style_image_id: l.style_image_id
            }
        });
        // let list = images.map((l, index) => {
        //     return {
        //         id: index + 1,
        //         order: index + 1,
        //         url: l.original,
        //         revision: l.revision,
        //         picture_angle: l.angle,
        //         description: l.description,
        //         style_image_id: l.style_image_id
        //     }
        // });
        console.log(list);
        setBoxes(list);
    }, [])


    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(boxes);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setBoxes(items);
    }



    async function update() {
        const user = await Auth.currentAuthenticatedUser();
        const idToken = user.signInUserSession.idToken.jwtToken;
        const res = await axios.get(`${process.env.REACT_APP_ENDPOINT}info/${id}`,
        {
            headers: {
                Authorization: idToken
            }
        }
    );
    setStylesFunc(res.data);
    return res.data;
    }

    const [mutate] = useMutation(handleSave, {
        onSuccess: () => {
            console.log("Success");
            queryCache.invalidateQueries("data");
        }
    })

    
function getRevisions(angle) {
    let revision = revisions.filter(style => {
        return style.picture_angle === angle;
    });



    return revision;
}

    async function handleSave() {
        const user = await Auth.currentAuthenticatedUser();
        const idToken = user.signInUserSession.idToken.jwtToken;

    setLoading(true);

    let list = boxes.map((box, index) => {
        // if (box.name !== ("0" + (index + 1))) {
            return {
                style_image_id: box.style_image_id,
                picture_angle: "0" + (index + 1),
                old_angle: box.picture_angle
            }
    });


    console.log(list);

    list.forEach(l => {
       let revisionList = (getRevisions(l.old_angle));   

       if (revisionList.length > 1) {

            revisionList.pop();

            revisionList.forEach(r => {
                list.push({
                 style_image_id: r.style_image_id,
                 picture_angle: l.picture_angle
                })
            })

       }

    })
    console.log(list);




    const request = await axios.put(`${process.env.REACT_APP_ENDPOINT}reorder`, list, {
        headers: {
            Authorization: idToken
        }
    });

    if (request.status === 200) {
        update();
        setReordering(false);
        setLoading(false);
    }
    console.log(request);




}


    return (

            <div className="ordering-container">
            <DragDropContext  onDragEnd={handleOnDragEnd}>


            <Droppable direction="horizontal" type="LIST" droppableId="characters">
                {(provided) => (
                <ul  className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                            {boxes.map(({url, id}, index) => {
                                if (url !== undefined) {
                                    return <Draggable  type="LIST"    draggableId={(id).toString()} index={index} key={id}>
                                                {(provided) => (
                                        <div className="image-card" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <Card 
                                                className="image-card"
                                                raised
                                                image={url} />
                                        </div>  
                                                )}

                                    </Draggable>
                                }


                            })}
                                  {provided.placeholder}
                    </ul>
    )}


                </Droppable>
                </DragDropContext>
                <div className="reorder-buttons">
                                    <button onClick={() => setReordering(false)} className="button1">
                                        Close
                                    </button>
                                    <button onClick={handleSave} className="button1">
                                        Save
                                    </button>
                </div>
            </div>


    )
}

export default Reordering;
